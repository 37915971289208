<template>
  <div class="app-content">
    <el-card class="box-card" v-loading="loading">
      <div class="clearfix">
        <el-row>
          <el-col :span="24">
            <el-form :inline="true" @submit.native.prevent>
              <el-form-item label="故障类型">
                <el-input @change="onQuerySubmit" v-model="query.name" placeholder="请输入故障类型"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onQuerySubmit">查询</el-button>
                <el-button type="primary" @click="faultCreate" v-if="permission.apiFaultTemplateCreate">添加模板</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>
      <div class="block">
        <el-row>
          <template>
            <el-tabs v-model="query.activeTab" @tab-click="handleClick">
              <el-tab-pane v-for="item in monitor" :key="item.id" :label="item.name" :name="item.name" :value="item.id"></el-tab-pane>
            </el-tabs>
          </template>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-table :data="tableData" stripe style="width: 100%">
              <el-table-column prop="name" label="故障类型">
              </el-table-column>
              <el-table-column prop="desc" label="故障描述">
              </el-table-column>
              <el-table-column prop="manufacturer" label="设备厂商">
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="180">
                <template slot-scope="scope">
                  <el-button @click="faultDatail(scope.row)" type="text" size="small">详情</el-button>
                  <el-button type="text" size="small" @click="deleteItem(scope.row)" v-if="permission.apiFaultTemplateDelete">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-pagination layout="prev, pager, next" prev-text="上一页" next-text="下一页" background @current-change="changeCurrent" :current-page="query.page"  :total="total"></el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { apiFaultTemplateList, apiFaultTemplateMonitor, apiFaultTemplateDelete } from "src/api/index";
import md5 from 'js-md5';
export default {
  components: {},
  data() {
    return {
      loading: false,
      query: {
        monitor_id: 1,
        name: '',
        page: 1,
        activeTab: '废水在线监测',
        logotype: 'basicTemplate'
      },
      tableData: [],
      total: 0,
      monitor: [],
    };
  },
  mounted() {
    const _this = this;
    //获取故障模板监测类型
    apiFaultTemplateMonitor()
    .then((res) => {
      let query = JSON.parse(_this.$localStorage.get(md5('query')))
      if (query === null) {
        _this.query = _this.query
      } else if (query.logotype === 'basicTemplate') {
        _this.query =  query;
      } else {
        _this.$localStorage.remove(md5("query"));
        _this.query.activeTab = res[0].name;
        _this.query.monitor_id = res[0].id;
      }
      _this.monitor = res;
      _this.changeCurrent(_this.query.page);
    })
    .catch((err) => {
      _this.loading = false;
      _this.error(err);
    })
    _this.pageInit(_this).then(() => {});
  },
  computed: mapState({
    url: (state) => state.url,
    permission: (state) => state.permission,
  }),
  watch: {
    '$route': 'getData'
  },
  methods: {
    ...mapActions(["pageInit"]),
    //查询故障模板列表
    onQuerySubmit() {      
      this.query.page = 1;
      let route = this.$router.history.current.path + '?page=' + this.query.page + '&name=' + this.query.name + '&monitor_id=' + this.query.monitor_id;
      let locat = decodeURI(this.$router.history.current.fullPath);
      if (route === locat) {
        this.getData();
      } else {       
        this.$router.replace(route);
      }
    },
    //切换分页
    changeCurrent(item) {
      this.query.page = item;
      let route = this.$router.history.current.path + '?page=' + this.query.page + '&name=' + this.query.name + '&monitor_id=' + this.query.monitor_id;
      let locat = decodeURI(this.$router.history.current.fullPath);
      if (route === locat) {
        this.getData();
      } else {       
        this.$router.replace(route);
      }
    },
    //切换顶栏菜单
    handleClick(tab) {
      this.query.monitor_id = tab.$attrs.value;
      this.onQuerySubmit();
    },
    //跳转添加模板页
    faultCreate() {
      this.$localStorage.set(md5("query"), JSON.stringify(this.query));
      this.$router.push('/admin/basic/template-add')
    },
    //跳转模板详情页
    faultDatail(item) {
      this.$localStorage.set(md5("query"), JSON.stringify(this.query));
      this.$router.push('/admin/basic/template-detail/' + item.id)
    },
    //获取故障模板列表数据
    getData() {
      const _this = this;
      _this.loading = true;
      apiFaultTemplateList(_this.query)
      .then((res) => {
        _this.loading = false;
        _this.tableData = res.data;
      })
      .catch((err) => {
        _this.loading = false;
        _this.error(err); 
      })
    },
    //删除模板信息
    deleteItem(item) {
      const _this = this;
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(() => {
        apiFaultTemplateDelete({id:item.id})
        .then((res) => {
          _this.$message({
            message: '删除成功',
            type: 'success',
          })
          _this.onQuerySubmit();
        })
        .catch((err) => {
          _this.error(err);
        })
      })
    },
    //错误信息提示
    error(err) {
      if (err.message === '没有操作权限') {
        return
      } else {         
        this.$message.error(err.message);
      } 
    },
  },
};
</script>
<style lang="less" scoped>
.app-content {
  background-color: #ffffff;
}
.el-pagination {
  text-align: center;
  margin-top: 27px;
}
.el-avatar--circle {
    position: relative;
    margin-top: 7px;
}
</style>